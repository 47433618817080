<template>
  <base-page
    :title="$t('keyDistribution.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.secretKeyFindList"
      :columns="columns"
      :defaultParams="params"
      :highlightCurrentRow="false"
      rowName="code"
      v-model="tableRow"
    >
      <!-- 分配时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>

      <!-- 有效期 -->
      <template v-slot:expirationDate="{ row }">
        {{ $util.format(row.expirationDate, 'yyyy-MM-dd') }}
      </template>
      <!-- 操作 -->
     <template v-slot:option="{ row }">
        <!-- lang:激活 -->
        <template v-if="row.isActivation === 'YES' ">
          <el-button
            v-if="$page.showBtn('KEY_AUTHORIZATION_MANAGE_ADD')"
            type="text"
            @click="disableMethod(row)" disabled
          >
            {{ $t('functionEnum.KEY_AUTHORIZATION_MANAGE_REVOCATION') }}
          </el-button>
        </template>
        <template v-else>
          <el-button
            v-if="$page.showBtn('KEY_AUTHORIZATION_MANAGE_ADD')"
            type="text"
            @click="disableMethod(row)" :disabled="row.enable === 'NORMAL' ? false : true "
          >
            {{ $t('functionEnum.KEY_AUTHORIZATION_MANAGE_DISABLE') }}
          </el-button>
        </template>
        <!-- lang:续期 -->
        <el-button
          v-if="$page.showBtn('KEY_AUTHORIZATION_MANAGE_ADD')"
          type="text"
          @click="renewalMethod(row)"
        >
          {{ $t('functionEnum.KEY_AUTHORIZATION_MANAGE_RENEWAL') }}
        </el-button>
      </template>
    </table-list>
     <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
     <renewal v-if="showRenewal" :tableRow="tableRow" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail'
import Renewal from './renewal'
import i18n from '@/lang'

export default {
  components: {
    Detail,
    Renewal
  },
  data() {
    let _that = this
    return {
      // 搜索
      formData: {},
      config: [
        // 密钥类型
        {
          tag: 'el-select',
          label: this.$t('keyDistribution.assignAccount'),
          span: 6,
          prop: 'type',
          tagProps: {
            options: this.$dict.showSecretKey(),
          },
        },
        // 是否撤回
        {
          tag: 'el-select',
          label: this.$t('keyDistribution.isBackOut'),
          prop: 'isBackOut',
          span: 6,
          tagProps: {
            options: this.$dict.showBackOut(),
          },
        },
        // 是否激活
        {
          tag: 'el-select',
          label: this.$t('keyDistribution.isActivation'),
          prop: 'isActivation',
          span: 6,
          tagProps: {
            options: this.$dict.showActivation(),
          },
        },

      ],
      // 显示表单
      showDetail:false,
      showRenewal:false,
      // 表格页
      tableRow: null,
      params: {
        targetCompanyId: this.$store.getters.company.id,
      },
      assignStatusEnum_1Obj: this.$util.listToObj(
        this.$dict.assignStatusEnum_1()
      ),
      resourceTypeEnumObj: this.$util.listToObj(this.$dict.resourceTypeEnum()),
      columns: [
        // lang:分配时间
        {
          label: this.$t('keyDistribution.allocateTime'),
          prop: 'createTime',
        },
        // lang:分配账号
        {
          label: this.$t('keyDistribution.assignAccount'),
          prop: 'userAccount',
          callback(row) {
             return row && row.userAccount && row.userAccount.name
          }
         //width: '150px',
        },
        // lang:密钥类型
        {
          label: this.$t('keyDistribution.keyType'),
          prop: 'type',
          callback(row) {
            if(row.type === 'PRODUCT_KEY'){
              return '产品密钥'
            }else{
              return '功能密钥'
            }
          }
        },
        // lang:产品版本
        {
          label: this.$t('keyDistribution.versionType'),
          prop: 'versionType',
          hidden: true,
          callback(row) {
            const version = _that.$dict.workflowProductVersion().filter(item => item.value === row.versionType)
            if (version.length > 0) {
              return version[0].label
            }
            return ''
          }
        },
        // lang:分配内容
        {
          label: this.$t('keyDistribution.content'),
          prop: 'contentType',
          callback(row) {
            if(row.type === 'PRODUCT_KEY') return _that.$util.getLocalStorage('apiprefix') === 'tmva' ? '远程指导' : '工作流'
            if(row.type === 'THERMAL_IMAGERY_FUNCTION_KEY') return '热成像'
            if(row.type === 'TEAM_VIEWER_FUNCTION_KEY') return '远程控制'
            if(row.type === 'SENSOR_COLLECT_FUNCTION_KEY') return '传感器采集'
            if(row.type === 'ENDOSCOPE_FUNCTION_KEY') return '内窥镜'
            if(row.type === 'WEB_EDIT_PRODUCT_KEY') return i18n.t('businessEnum.WEB_EDIT_PRODUCT_KEY')
          }
        },
        // lang:密钥激活码
        {
          label: this.$t('keyDistribution.keyActivationCode'),
          prop: 'code',
        },
        // lang:有效期
        {
          label: this.$t('keyDistribution.termOfValidity'),
          prop: 'expirationDate',
        },
        // lang:分配状态
        {
          label: this.$t('keyDistribution.allocationStatus'),
          prop: 'enable',
          callback(row) {
            if(row.enable === "NORMAL")  return '已分配'
            if(row.enable === "DISABLE")  return '已禁用'
          }
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '150px',
        },
      ],
    }
  },
  methods: {
    // 关闭详情页回调
    closePage() {
      this.showDetail = false
      this.showRenewal = false
      this.search()
    },
     // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showDetail = true
          break
        default: // do something
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 撤回
    disableMethod(row) {
      this.$element
        .showConfirm({
          content: this.$t('base.disableConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.secretKeyDisable,
            data: {
              id: row.id
            },
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.disableSuccess'), 'success')
            this.search()
          })
        })
    },
    // 激活
    activeMethod(row) {
      this.$element
        .showConfirm({
          content: this.$t('base.revocationConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.secretKeyActive,
            data: {
              id: row.id
            },
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.revocationSuccess'), 'success')
            this.search()
          })
        })
    },
    // 续期
    renewalMethod(row) {
      this.tableRow = row
      this.showRenewal = true
    }
  },
}
</script>
